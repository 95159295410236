import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["concatField", "concatValue"]

  connect() {
    if (this.hasConcatValueTarget) {
      this.concat()
    }
  }

  concat() {
    if (!this.concatFieldTarget.disabled) {
      this.concatFieldTarget.value = this.concatenatedValues()
    }
  }

  concatenatedValues() {
    let concatString = ''
    this.concatValueTargets.forEach((concatValue) => {
      concatString = concatString + this.getValue(concatValue)
    })
    return concatString
  }

  getValue(concatValue) {
    return concatValue.options[concatValue.selectedIndex].dataset.concatValue || ''
  }
}
