import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addItem', 'template', 'destroyField', 'previewTemplate', 'previewAppend']
  static values = { index: String }

  connect() {
    this.hideDestroyed()
  }

  hideDestroyed() {
    const destroyFields = this.destroyFieldTargets
    if (destroyFields) {
      destroyFields.forEach((target) => (target.value === 'true' || target.value === '1') ? this.hideNestedFields(target) : '')
    }
  }

  addAssociation(event) {
    event.preventDefault()
    const indexRef = this.indexValue || 'TEMPLATE_RECORD'
    const re = new RegExp(indexRef,"g")
    const index = Math.floor(new Date().valueOf() * Math.random())
    const content = this.templateTarget.innerHTML.replace(re, index)
    this.addItemTarget.insertAdjacentHTML('beforebegin', content)

    this.addPreview(index)
  }

  addPreview(index) {
    if (!this.hasPreviewTemplateTarget) return;

    const re = new RegExp('PREVIEW_INDEX',"g")
    const content = this.previewTemplateTarget.innerHTML.replace(re, index)
    this.previewAppendTarget.insertAdjacentHTML('beforeend', content)
  }

  removeAssociation(event) {
    event.preventDefault()
    this.hideNestedFields(event.target)
  }

  hideNestedFields(target) {
    let item = target.closest('.nested-fields')
    let checkbox = item.querySelector("input[name*='_destroy']")
    checkbox.value = 1
    item.style.display = 'none'

    this.hidePreview(checkbox)
  }

  hidePreview(target) {
    const previews = document.querySelectorAll(target.dataset.previewSelector)
    previews.forEach((preview) => {
      preview.style.display = 'none'
    })
  }
}