import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["reveal"]
  static classes = ["hidden"]

  connect() {
    const fieldsWithErrors = this.element.querySelectorAll('.field_with_errors')
    if (fieldsWithErrors.length) {
      this.toggle()
    }
  }

  toggle() {
    this.revealTargets.forEach((elem) => elem.classList.toggle(this.hiddenClass))
  }
}
