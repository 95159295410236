import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["mobileMenu", "burgerIcon", "closeIcon"]
  static classes = ["hidden"]

  toggle() {
    this.mobileMenuTarget.classList.toggle(this.hiddenClass)
    this.burgerIconTarget.classList.toggle(this.hiddenClass)
    this.closeIconTarget.classList.toggle(this.hiddenClass)
  }
}
