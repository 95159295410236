import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['reveal', 'radioButton']
  static classes = ['hidden']

  connect() {
    this.update()
  }

  update() {
    if (this.hasRadioButtonTarget) {
      this.radioButtonTargets.forEach((radioButton) => this.setRevealState(radioButton))
    }
  }

  setRevealState(radioButton) {
    const selector = radioButton.dataset.radioRevealSelector
    const revealTargets = document.querySelectorAll(selector)
    revealTargets.forEach((target) => {
      if (radioButton.checked) {
        target.classList.remove(this.hiddenClass)
      }
      else {
        target.classList.add(this.hiddenClass)
      }
    })
  }
}
