import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["source", "sourceColor"]

  connect() {
    this.sourceTargets.forEach((source) => {
      const targets = document.querySelectorAll(source.dataset.previewTargetSelector)
      targets.forEach((target) => target.innerText = source.value)
    })

    // For color fields
    this.sourceColorTargets.forEach((sourceColor) => {
      const targets = document.querySelectorAll(sourceColor.dataset.previewTargetSelector)
      const style_properties = sourceColor.dataset.previewTargetStyleProperties.split(',')
      targets.forEach((target) => {
        // Check target has the style attribute
        if(target.getAttribute("style")){
          // Loop through the style properties scope
          style_properties.forEach((property) => {
            // Only update the style property if it already exists on the target
            if(target.getAttribute("style").indexOf(`${property}:`) != -1) {
              target.style.setProperty(property, sourceColor.value)
            }
          })
        }
      })
    })
  }

  updatePreview(e) {
    const targets = document.querySelectorAll(e.currentTarget.dataset.previewTargetSelector)
    const style_property_targets = e.currentTarget.dataset.previewTargetStyleProperties

    // For color fields
    if (style_property_targets) {
      const style_properties = style_property_targets.split(',')
      targets.forEach((target) => {
        if(target.getAttribute("style")){
          style_properties.forEach((property) => {
            if(target.getAttribute("style").indexOf(`${property}:`) != -1) {
              target.style.setProperty(property, e.currentTarget.value)
            }
          })
        }
      })
    }
    else {
      targets.forEach((target) => target.innerText = e.currentTarget.value)
    }
  }
}
