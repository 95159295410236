import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['positionField', 'itemNumber']

  connect() {
    this.refresh()
    this.sortable = Sortable.create(this.element, {
      handle: '.handle',
      animation: 150,
      onEnd: this.refresh.bind(this)
    })
  }

  refresh(event) {
    this.refreshPositions(event)
    this.refreshItemNumbers(event)
  }

  refreshPositions(event) {
    const positionFields = this.positionFieldTargets
    if (positionFields) {
      positionFields.reverse().forEach((position, index) => position.value = index + 1)
    }
  }

  refreshItemNumbers(event) {
    const itemNumbers = this.itemNumberTargets
    if (itemNumbers) {
      itemNumbers.forEach((number, index) => number.innerText = index + 1)
    }
  }
}
