import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["element", "selectField", "showOnSelect"]
  static classes = ["hidden"]

  connect() {
    this.toggleShowOnSelectTargets()
    if (this.hasSelectFieldTarget) {
      if (this.hasElementTarget) {
        this.elementTargets.forEach((target) => this.toggle(target, target.dataset.selectToggleShowOn, this.selectFieldTarget))
      }
    }
  }

  refresh() {
    this.refreshElements(this.selectFieldTarget)
  }

  changed(event) {
    this.refreshElements(event.target)
  }

  refreshElements(selectElement) {
    this.toggleShowOnSelectTargets()
    if (this.hasElementTarget) {
      this.elementTargets.forEach((target) => this.toggle(target, target.dataset.selectToggleShowOn, selectElement))
    }
  }

  toggleShowOnSelectTargets() {
    if (this.hasShowOnSelectTarget) {
      if (this.selectFieldTarget.value !== '') {
        this.showOnSelectTargets.forEach((target) => target.classList.remove(this.hiddenClass))
      }
      else {
        this.showOnSelectTargets.forEach((target) => target.classList.add(this.hiddenClass))
      }
    }
  }

  toggle(element, values, selectedOption) {
    if (element && values) {
      const selectedValue = selectedOption.value
      const selectedAltValue = selectedOption.options[selectedOption.selectedIndex].dataset.altValue

      let hidden = true;
      for (let _value of values.split(' ')) {
        if (_value === selectedAltValue || _value === selectedValue) {
          hidden = false;
        }
      }

      const errorDiv = this.hasErrorWrapperDiv(element)
      const selectedCheckBox = this.hasSelectedCheckBox(element)

      if (hidden) {
        element.classList.add(this.hiddenClass)
        if (errorDiv) {
          errorDiv.classList.add(this.hiddenClass)
        }
        if (selectedCheckBox) {
          selectedCheckBox.checked = false
        }
        this.updateReadOnlyState(element, true)
      }
      else {
        element.classList.remove(this.hiddenClass)
        if (errorDiv) {
          errorDiv.classList.remove(this.hiddenClass)
        }
        this.updateReadOnlyState(element, false)
      }
    }
  }

  updateReadOnlyState(element, state = true) {
    // We add the readonly and disabled attributes so the field value is ignored on submission
    this.setReadOnlyState(element, state)

    // Also set for any nested fields with target data attribute
    var fields = element.querySelectorAll('[data-select-toggle-toggle-readonly]')
    if (fields) {
      fields.forEach((field) => this.setReadOnlyState(field, state))
    }
  }

  setReadOnlyState(field, state) {
    // If field has 'disabled' class, ignore it
    if (field.classList.contains('disabled')) { return; }

    // Set state
    field.readOnly = state
    field.disabled = state

    // If field uses slimselect then enable/disable that too
    let ss = field.slim;
    if (!ss) { return; }
    (state) ? ss.disable() : ss.enable();
  }

  hasErrorWrapperDiv(element) {
    // Rails automatically adds a wrapper div to fields with errors
    // So we make sure we hide/show this also
    return element.closest('.field_with_errors')
  }

  hasSelectedCheckBox(element) {
    return element.querySelector('input[type=\'checkbox\']:checked')
  }
}