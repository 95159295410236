import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["swapper", "swappee"]
  static classes = ["hidden"]

  swap() {
    this.swapperTarget.classList.toggle(this.hiddenClass)
    this.swappeeTarget.classList.toggle(this.hiddenClass)
  }
}
