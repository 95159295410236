import { Tabs } from "tailwindcss-stimulus-components"

export default class TabFields extends Tabs {
  showTab() {
    super.showTab()

    // Ensure the child radio button is checked when tab is selected
    this.tabTargets.forEach((tab, index) => {
      if (index === this.index) {
        tab.querySelector("input[type='radio']").checked = true
      }
    })
  }
}