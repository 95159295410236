// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Assets
import "stylesheets/application"
const images = require.context('../images', true)

// Start StimulusJS
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
import { Dropdown, Modal, Tabs, Popover, Toggle } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)

// Import and register Stimulus Carousel
// NOTE: swiper 6 first had to be installed before installing stimulus-carousel
// https://github.com/stimulus-components/stimulus-carousel/issues/3#issuecomment-989849211
import Carousel from "stimulus-carousel"
application.register("carousel", Carousel)
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// Local Time support
import LocalTime from "local-time"
LocalTime.start()

// Import and register the slimselect
import StimulusSlimSelect from "stimulus-slimselect"
application.register('slimselect', StimulusSlimSelect)