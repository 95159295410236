import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["checkBox"]

  selectAll(event) {
    event.preventDefault()
    if (this.hasCheckBoxTarget) {
      this.checkBoxTargets.forEach((target) => this.toggleIfVisibile(target, true))
    }
  }

  deselectAll(event) {
    event.preventDefault()
    if (this.hasCheckBoxTarget) {
      this.checkBoxTargets.forEach((target) => this.toggleIfVisibile(target, false))
    }
  }

  toggleIfVisibile(target, checkedState) {
    if (this.isHidden(target)) {
      return
    }
    target.checked = checkedState
  }

  isHidden(el) {
    return (el.offsetParent === null)
  }
}
