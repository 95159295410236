import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["viewFilterable"]
  static classes = ["hidden"]

  showAll() {
    this.viewFilterableTargets.forEach((elem) => elem.classList.remove(this.hiddenClass))
  }

  hideOthers(e) {
    this.showAll()
    this.viewFilterableTargets.forEach((elem) => {
      if (elem.dataset.viewFilterId != e.target.dataset.viewFilterId) {
        elem.classList.add(this.hiddenClass)
      }
    })
  }
}
