import { Controller } from '@hotwired/stimulus'

const CUSTOM_SELECT_OPTION_VALUE = 'custom_select_option'

export default class extends Controller {
  static targets = ["selectField", "textField"]
  static values = { selectable: Boolean }
  static classes = ["hidden", "smallSelect"]

  connect() {
    if (this.selectableValue) {
      this.textFieldTarget.value = ''
      this.hideTextField()
    }
    else {
      this.showTextField()
    }
  }

  change() {
    if (this.selectFieldTarget.value === CUSTOM_SELECT_OPTION_VALUE) {
      this.showTextField()
      this.textFieldTarget.select()
    }
    else {
      this.hideTextField()
    }
  }

  hideTextField() {
    this.selectFieldTarget.classList.remove(...this.smallSelectClass.split(' '))
    this.textFieldTarget.classList.add(this.hiddenClass)

    // We add the readonly and disabled attributes so the field value is ignored on submission
    this.textFieldTarget.readOnly = true
    this.textFieldTarget.disabled = true
  }

  showTextField() {
    this.selectFieldTarget.value = CUSTOM_SELECT_OPTION_VALUE
    this.selectFieldTarget.classList.add(...this.smallSelectClass.split(' '))
    this.textFieldTarget.classList.remove(this.hiddenClass)

    // We ensure the text field isn't ignored on form submission
    // The text field overwrites the value of the select field as it has the same name attribute value
    this.textFieldTarget.readOnly = false
    this.textFieldTarget.disabled = false
  }
}
