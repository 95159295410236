import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["checkBox"]
  static classes = ["checked"]

  connect() {
    this.setState()
  }

  setState() {
    if (this.hasCheckBoxTarget) {
      this.checkBoxTargets.forEach((checkbox) => this.toggle(checkbox))
    }
  }

  toggle(checkbox) {
    let label = checkbox.closest('label')
    if (checkbox.checked) {
      label.classList.add(this.checkedClass)
    }
    else {
      label.classList.remove(this.checkedClass)
    }
  }

  update(event) {
    this.toggle(event.target)
  }
}
