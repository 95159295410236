import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["revealAll"]
  static classes = ["hidden"]

  showAll() {
    this.revealAllTargets.forEach((elem) => elem.classList.remove(this.hiddenClass))
  }

  hideAll() {
    this.revealAllTargets.forEach((elem) => elem.classList.add(this.hiddenClass))
  }
}
