import SlimSelect from 'slim-select'
import StimulusSlimSelect from 'stimulus-slimselect'

export default class extends StimulusSlimSelect {
  static values = {
    options: Object
  };
  connect() {
    console.log(this.optionsValue)
    this.slimselect = new SlimSelect({
      select: this.element,
      addable: (value) => value,
      ...this.optionsValue
    });
  }
}